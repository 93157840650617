:root {
    --header-height: 173px;
}

#unauthorizedMain{
    box-shadow: inset 0 5px 10px -5px black;
    background: #ffd66eaa;
    height: calc(100vh - var(--header-height));
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#unauthorizedP{
    color: white;
    font-size: 2.5em;
    text-shadow: 2px 2px 2px #000000;
}

#font401{
    font-size: 4em;
    text-shadow: 2px 2px 2px #000000;
}

#stopSignOuter{
    margin-top: 20px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}

#stopSignInner{
    width: 70px;
    height: 20px;
    background-color: #fff;
    border-radius: 2px;
}