.aboutUsCnt{
    padding: 0px 25px 0px 25px;
}

.aboutImg{
    width: 500px;
    margin: 20px 0px 20px 20px;
    float: right;
}

.bottomParagraph{
    margin-bottom: 20px;
}

@media only screen and (max-width: 800px) {
    .aboutImg{
        width: 100%;
        margin: 20px 0px 20px 0px;
    }
}