.contactUsCnt{
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.emailIconDiv{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #A7D8FF;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 25px;
    transition: 0.2s ease-in-out;
}

.emailIconDiv:hover{
    background-color: #FFCD51;
}

.emailIcon{
    width: 60px;
}

.emailLink{
    font-size: 1.27em;
    transition: 0.2s ease-in-out;
}

.emailLink:hover{
    font-size: 1.3em;
    color: #FFCD51;
}