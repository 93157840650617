.myUploadCardCtn{
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-between;
}

.myUploadCard{
    display: flex;
    justify-content: space-between;
    background: #A7D8FF;
    width: 45%;
    height: 170px;
    border-radius: 10px;
    margin: 20px;
}

.myUploadCard-ImageCtn{
    width: 30%;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 5px;
}

.myUploadCard-Image{
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
}

.myUploadCard-TextCtn{
    width: 60%;
    word-wrap:break-word;
    padding: 5px;
}

.myUploadCard-IconsCtn{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 10%;
    border-left: 3px solid white;
}

.myUploadCard-Icons{ 
    cursor: pointer;
}

@media only screen and (max-width: 800px) {
    .myUploadCard{
        width: 100%;
        height: 205px;
    }

    .myUploadCard-IconsCtn{
        width: 15%;
    }
}