.cardsCategoryContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
}

#asideMargin{
    height: 100%;
    width: 180px;
    margin: 0px 30px 15px 15px;
}

@media only screen and (max-width: 800px) {
    #asideMargin{
        display: none;
    }
}