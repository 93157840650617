#usersReviewsContainer{
    display: flex;
    flex-direction: column-reverse;
}

.reviewContainer{
    display: flex;
    background-color: #A7D8FF33;
    min-height: 120px;
    margin: 0px 20px 15px 20px;
    border-radius: 10px;
    background-clip:  padding-box;
    border: 10px solid #A7D8FF33;
}

.reviewContainer > div{
    padding: 10px;
}

.usersReviewDetails{
    flex: 20%;
    border-right: 5px solid white;
    display: flex;
    justify-content: left;

}

.usersReviewDetails :nth-child(2){
    display: flex;
    flex-direction: column;
}

.usersReviewDetails :nth-child(2) :nth-child(3){
    display: flex;
    align-items: center;    
}
.commentDiv{
    flex: 60%;
    word-wrap: break-word;
}

.usersPrintImage{
    flex: 20%;
    display: flex;
    justify-content: right;
    max-height: 200px;
}

.userAvatarDiv{
    margin-right: 15px;
}

.commentImage{
    object-fit: contain;
    max-width: 100%;
    cursor: pointer;
}

@media only screen and (max-width: 800px) {

    .userAvatarDiv{
        display: flex;
        align-items: center;
    }
    
    #addReviewContainer{
        flex-wrap: wrap;
    }

    #commentTextArea{
        margin-bottom: 10px;
    }

    .reviewContainer{
        max-height: 100%;
        flex-direction: column;
    }

    .usersReviewDetails{
        border-right: none;
        border-bottom: 5px solid white;
    }

    .usersPrintImage{
        justify-content: center;
    }

}