.supportUsCnt{
    padding: 0px 25px 20px 25px;
    display: flex;
}

.donateImgCnt{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.donateImg{
    width: 500px;
    margin: 20px 0px 20px 20px;
    float: right;
}

.donateBtn{
    width: 150px;
}


@media only screen and (max-width: 800px) {
    .supportUsCnt{
        flex-direction: column;
    }

    .donateImgCnt{
        display: flex;
        justify-items: center;
    }
    
    .donateImg{
        width: 100%;
        margin: 20px 0px 20px 0px;
    }
}